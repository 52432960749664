import { useEffect, useState } from "react"
import { event } from "../utils/gtag"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import createFirebaseApp from "../firebase/firebase"
import {
  useSignInWithFacebook,
  useSignInWithGoogle,
  useSignInWithTwitter,
} from "react-firebase-hooks/auth"
import getRefinedFirebaseAuthErrorMessage from "../utils/firebase"
import Link from "next/link"
import AuthErrors from "../components/Auth/AuthErrors"
import SocialAuth from "../components/Auth/SocialAuth"
import useRouterStore from "stores/routerStore"
import useSnackbarStore from "stores/snackbar"
import useT from "utils/useT"
import useSessionStore from "stores/session"

const app = createFirebaseApp()
const auth = getAuth(app)

const Login = () => {
  const t = useT()
  const toggleToast = useSnackbarStore((state) => state.toggleToast)
  const push = useRouterStore((state) => state.router?.push)
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setPending] = useState(false)
  const [{ email, password }, setLogin] = useState({
    email: "",
    password: "",
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [signInWithFacebook, facebookUser, facebookLoading, facebookError] =
    useSignInWithFacebook(auth)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [signInWithGoogle, googleUser, googleLoading, googleError] =
    useSignInWithGoogle(auth)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [signInWithTwitter, twitterUser, twitterLoading, twitterError] =
    useSignInWithTwitter(auth)

  const isLoggedIn = useSessionStore((state) => state.isLoggedIn)

  useEffect(() => {
    isLoggedIn && push?.("/")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLogin = () => {
    setPending(true)
    if (email && password) {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user
          setErrorMessage("")
          if (user) {
            // User is signed in.
            push?.("/")
            toggleToast({
              color: "green",
              text: `${t.session.welcome} ${
                user?.displayName ?? t.session.toAppollow
              }`,
            })

            event({
              action: "Log_in",
              category: "Accounts",
              label: "Account Logged in",
              value: `User Name: ${user?.displayName}`,
            })
          }
        })
        .then(() => setPending(false))
        .catch((error) => {
          const formattedMessage =
            getRefinedFirebaseAuthErrorMessage(error?.message) ?? error?.message
          setErrorMessage(formattedMessage)
          setPending(false)
        })
    }
  }
  const formattedGoogleError = getRefinedFirebaseAuthErrorMessage(
    googleError?.message,
  )

  const formattedFacebookError = getRefinedFirebaseAuthErrorMessage(
    facebookError?.message,
  )

  const formattedTwitterError = getRefinedFirebaseAuthErrorMessage(
    twitterError?.message,
  )

  return (
    <div className="card card-style">
      <div className="content mt-3 mb-0">
        <div className="input-style has-borders has-icon validate-field mb-4">
          <i className="fa fa-user"></i>
          <input
            type="email"
            className="form-control validate-name"
            id="form1a"
            placeholder={t.signup.email}
            onChange={(e) =>
              setLogin((prevState) => ({ ...prevState, email: e.target.value }))
            }
          />

          <em>({t.signup.required})</em>
        </div>
        <div className="input-style has-borders has-icon validate-field mb-4">
          <i className="fa fa-lock"></i>
          <input
            type="password"
            className="form-control validate-password"
            id="form3a"
            placeholder={t.login.password}
            onChange={(e) =>
              setLogin((prevState) => ({
                ...prevState,
                password: e.target.value,
              }))
            }
          />

          <em>({t.signup.required})</em>
        </div>
        <button
          disabled={!Boolean(email && password) || loading}
          onClick={handleLogin}
          className="btn btn-m mt-2 mb-4 btn-full w-100 bg-green-dark rounded-sm text-uppercase font-900"
        >
          {loading ? (
            <div
              className="spinner-border spinner-border-sm"
              role="status"
            ></div>
          ) : (
            t.login.login
          )}
        </button>
        <AuthErrors
          errorMessage={errorMessage}
          formattedFacebookError={formattedFacebookError}
          formattedGoogleError={formattedGoogleError}
          formattedTwitterError={formattedTwitterError}
        />
        <div className="divider"></div>
        <p className="text-center font-12 mt-n3">{t.signup.signInWith}</p>
        <SocialAuth
          signInWithFacebook={signInWithFacebook}
          facebookLoading={facebookLoading}
          signInWithGoogle={signInWithGoogle}
          googleLoading={googleLoading}
          signInWithTwitter={signInWithTwitter}
          twitterLoading={twitterLoading}
        />
        <div className="divider mt-4 mb-3"></div>
        <div className="d-flex">
          <div className="w-50 font-11 pb-2 color-theme opacity-60 pb-3 text-start">
            <Link href="/signup" className="color-theme">
              {t.signup.createAccount}
            </Link>
          </div>
          <div className="w-50 font-11 pb-2 color-theme opacity-60 pb-3 text-end">
            <Link href="/password-reset" className="color-theme">
              {t.login.forgot}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
